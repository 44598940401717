<script setup lang="ts">
import HeaderVue from "@/views/components/Header.vue";
// import HeaderClient from "@/views/components/HeaderClient.vue";
import FooterVue from "@/views/components/Footer.vue";
import useAuthStore from "@/stores/auth.ts";
import { useUserStore } from "@/stores/user.ts";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
import { getUserFromLocalStorage } from "@/utils/functions.ts";
import { IUser } from "@/models/interfaces/interfaces.ts";

// Local variables
const route = useRoute();

// Stores
const auth = useAuthStore();
let userStore = useUserStore();

const props = withDefaults(
  defineProps<{
    canShowFooter?: boolean;
  }>(),
  {
    canShowFooter: true,
  }
);

// Lifecycle Hooks
onMounted(() => {
  if (auth.isLoggedIn) {
    const user = getUserFromLocalStorage()
    userStore.addUser(user as IUser);
  } 
});
</script>

<template>
<!--  <template v-if="route.name !== 'sign_redirect'">-->
<!--    <HeaderClient v-if="auth.getIsLoggedIn"></HeaderClient>-->
    <HeaderVue />
<!--  </template>-->

  <!-- <router-view></router-view> -->
  <slot></slot>

  <FooterVue v-if="props.canShowFooter"/>
</template>
